.navContent {
  display: flex;
  justify-content: space-between;
}
/* @media only screen and (max-width: 600px) {
  .navContent {
    flex-direction: column;
  }
} */
.navContent_left {
  margin-right: 20px;
}
.navContent_left,
.navContent_right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
}
.navContent_left>* {
  margin: 0 !important;
}

.formRow {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .formRow {
    flex-direction: column;
    align-items: flex-start;
  }
}
.formLabel {
  min-width: 130px;
  max-width: 130px;
}
.modalHeader {
  margin-top: 20px;
}
.modal-title {
  display: flex;
  justify-content: space-between;
  width:100%
  
}


.logo {
  display: flex;
  font-size: 1.2rem;
}
.logoMain {
  font-weight: bold;
  color: black;
}
.logoDegree {
  color: red;
}

.listItem {
  display: flex;
  margin: 20px 0;
  color: black;
  font-weight: bold;
}
.counter {
  margin-right: 10px;
}
.lightBlueText {
  color: #bbc1f0;
}
.listItem_content {
}
/* section */
.section {
  overflow: hidden;
  /* height: 700px; */
}
.section_photo_wrapper {
  position: relative;
}
.section_photo {
  z-index: -1;
  position: absolute;
  right: 0;
  /* height: 600px; */
  width: 55%;
}
.smallPhoto {
  height: 400px;
}
.inverted_section_photo {
  z-index: -1;
  position: absolute;
  left: 0;
  /* height: 600px; */
  width: 55%;
}
.section_content {
  max-width: 480px;
}
.inverted_section_content {
  max-width: 400px;
  margin-left: 70%;
}
@media only screen and (max-width: 1200px) {
  .inverted_section_content {
    margin-left: 55%;
  }
}

@media only screen and (max-width: 600px) {
  .section {
    display: flex;
    flex-direction: column;
  }
  .section_photo_wrapper {
    margin: 0 auto;
  }
  .section_photo, .inverted_section_photo {
    position: static;
    width: auto;
    max-height: 200px;
  }
  .section_content {
    margin-left: 20px;
    margin-right: 20px;
  }
  .inverted_section_content {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    
  }
}

/* article */
.article {
  margin: 80px 0;
}
@media only screen and (max-width: 600px) {
  .article {
    margin: 50px 20px;
  }
}
.article_header {
  margin: 0 0 20px;
  line-height: 50px;
}
.smallHeader {
  font-size: 2rem;
}
.article_description {
  margin-bottom: 25px;
}
.subheader {
  font-size: 0.8rem;
  font-weight: bold;
}

/* stepCard */
.steps {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepCardContent {
  font-family: sans-serif !important;
  display: grid;
  grid-template-columns: 1fr 1fr;

}
.stepCard {
  padding: 30px;
  max-width: 600px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .stepCard {
    width: 90%;
  }
}
.stepCard_header {
  margin: 20px 0;
}

.stepCard_line {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
}

.dot {
  color: rgba(0, 0, 0, 0.07);
  font-size: 0.7rem;
  line-height: 1.2rem;
  margin: 0 5px;
}

.listHeader {
  margin: 15px 0;
}

.horizontalButton {
  min-width: 240px;
}
/* start Project cArd */
.startProject_card {
  max-width: 60vw;
  margin: 100px auto;
  background-color: #f2f4ff !important;
  padding: 80px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .startProject_card {
    max-width: 90vw;
    padding: 40px;
    margin: 40px auto;
  }
}
.startProject_card_header {
  line-height: 4rem;
  max-width: 480px;
  margin: 40px auto;
}
.gradientV {
  height: 100px;
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgba(4, 2, 57, 0.1));
}
.gradientV_reverse {
  height: 100px;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(4, 2, 57, 0.1));
}
.lineSeparator {
  height: 1px;
  width: 100%;
  background-color: rgba(4, 2, 57, 0.1);
}

.checkboxPadding {
  padding-right: 25px;
  padding-top: 2px;
}
@media only screen and (max-width: 600px) {
  .checkboxPadding {
    min-width: 0;
    margin-bottom: 20px;
  }
}

/* gallery */
.gridGallery {
  margin: 100px 0;
}
.gridGallery_header {
  margin-bottom: 40px;
  margin-top: 20px
}
.gridGallery_content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 40px;
  justify-items: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .gridGallery {
    margin-left: 20px;
    margin-right: 20px;
  }
  .gridGallery_content {
    grid-template-columns: repeat(1, auto);
  }
}
.gridGallery_image_wrapper {
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridGallery_image {
  height: 40px;
}
.gridGallery_image_big {
  height: 90px;
}
.gridGallery_description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}
.gridGallery_description_trigger {
  margin: 20px 0;
  cursor: pointer;
  color: #1f3dc3;

}

/* development */
.development_mainCard, .development_card {
  padding: 30px;
}
.development_mainCard {
  margin: 30px;
  display: grid !important;
  grid-gap: 80px;
  grid-template-columns: 2fr 1fr;
}
.development_card {
  margin: 30px 30px 60px;
  min-height: 325px;
}
.development_row {
  display: grid;
  grid-template-columns: 1fr 1fr
}
@media only screen and (max-width: 600px) {
  .development_row {
    grid-template-columns: 1fr;
  }
  .development_mainCard {
    margin: 10px;
    grid-template-columns: 1fr;
    grid-gap: 10px;

  }
  .development_card {
    margin: 10px 10px 20px;
  }
}

/* actor */
.actor {
  margin: 20px 0;
  display: flex
}
.actor_data {
  margin-left: 20px;
}
.actor_name, .actor_position {
  font-weight: bold;
  color: black;
}

.partnerLogoLink img { 
  position: relative; 
  top: 0;
  transition: top ease 0.2s;
}
.partnerLogoLink img:hover { 
  top: -5px;
}

/* jobs */
.jobOpenings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 60px;
}
.jobOpenings_header {
  margin-bottom: 40px;
  text-align: center;
}
.jobOpening {
  border: solid 2px rgba(4, 2, 57, 0.1);
  border-radius: 10px;
  min-width: 500px;
  padding: 15px 20px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .jobOpening {
    min-width: 90vw;
    max-width: 90vw;
    /* padding: 40px;
    margin: 40px auto; */
  }
}
.jobOpeningInfo_position {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 10px;
  color: black;
}
.jobButton {
  min-width: 110px;
}

.storyList {
  margin: 0 40px;
}

/* footer */
.preFooter {
  background-color: #191847;
  padding: 40px;
  color: white;
}
.footer {
  background-color: #040239;
  padding: 40px 40px 10px;
  color: white;
  font-size: 0.8rem;
}
.footerRow {
  display: flex;
}
.footerCol {
  display: flex;
  flex-direction: column;
}
.footerContent {
  display: flex;
  flex-wrap: wrap;
}
.footerColumn {
  min-width: 100px;
  margin-right: 100px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
  .footerColumn {
    margin-right: 30px;
  }
}
.footerColumnHeader {
  min-height: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.footerColumnItem {
  margin-bottom: 1px;
}

/* override UI kit */
.modalBackdrop.modalBackdrop {
  opacity: 0.5 !important;
  background-color: #040239 !important;
}
.modal-body {
  overflow-y: auto;
  max-height: 60vh;
}
.alert {
  display: flex;
  justify-content: center;
  padding: 15px 20px 5px !important;
}
.alertBar {
  margin-bottom: 0 !important;
}
.btn-primary {
  background-color: #1f3dc3 !important;
  font-weight: 500 !important;
}
a {
  text-decoration: none !important;
  color: black !important
}
h2 {
  font-size: 44px !important;
  line-height: 3rem;
}
h3 {
  font-size: 30px !important;
}
h1 {
  font-size: 50px !important;
}
ul {
  font-size: 0.8rem;
}

/* helpers */
.white {
  color: white !important;
}
.pinkBg {
  background-color: #ff2a6c !important;
  border-color: #ff2a6c !important;
  color: white !important
}
.alignRight {
  display: flex !important;
  justify-content: flex-end;
}
.alignTop {
  display: flex !important;
  align-items: flex-start;
}
.noMargin {
  margin: 0 !important;
}
.noPadding {
  padding: 0 !important;
}
.widthMax400 {
  max-width: 400px;
}
.center600 {
  margin: 0 auto;
  max-width: 600px;
}
.horizontal {
  display: flex;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .center {
    flex-direction: column;
  }
}
.twoSideBySide {
  display: grid;
  grid-template-columns: auto auto;
}

.verticalSpacer_100 {
  height: 100px;
}
.verticalSpacer_50 {
  height: 50px;
}
.verticalSpacer_20 {
  height: 20px;
}
.verticalSpacer_10 {
  height: 10px;
}
@media only screen and (max-width: 600px) {
  .verticalSpacer_100, .verticalSpacer_50, .verticalSpacer_20 {
    height: 0px;
  }
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

input[type=file]::-webkit-file-upload-button {
  border: 1px solid #becad6;
  padding: .3rem 1rem;
  border-radius: .375rem;
  background-color: white;
  color: grey;
  transition: 1s;
}
input[type=file]::file-selector-button {
  border: 1px solid #becad6;
  padding: .3rem 1rem;
  border-radius: .375rem;
  background-color: white;
  color: grey;
  transition: 1s;
}
input[type=file]::-webkit-file-upload-button:hover{
  background-color: rgba(0,0,0,0.1);
  border-color: #9ba5af
}
input[type=file]::file-selector-button:hover {
  background-color: rgba(0,0,0,0.1);
  border-color: #9ba5af
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}
input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color:#e9e9e9;
}
input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color:#1f3dc3;
}
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 4px;
  left: 8px;
}

.close {
  position: absolute;
  right: 52px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
